/**
* MAIN SCSS
*/
@import "sb-basegrid";
/**
* F O N T S
*/
@import "../fonts/fonts";
/**
* V A R 
*/
// couleurs
$black: #000000;
$black-middle: #222222;
$black-light: #363636;
$grey: #dfded7;
$orange: #ffaa00;
// size
$izeOrangeBorder: 15px;
/**
*   M I X I N S
*/
@mixin trs($time, $affect: all) {
  transition: $affect $time cubic-bezier(0.8, 0, 0.2, 1);
}
/**
* A N I M A T I O N S
*/
@keyframes slideTitle {
  0% {
    left: -75%;
  }
  100% {
    left: 75%;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeInLoop {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.fadeIn {
  animation: fadeIn forwards 0.4s ease-in-out;
}
@keyframes logoLoaderMask {
  0% {
    left: -100px;
  }
  50% {
    left: 200px;
  }
  100% {
    left: 200px;
  }
}
/**
* F U N C T I O N S
*/
$baseFontSize: 16;
@function rem($pixels, $context: $baseFontSize) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }

  @if (unitless($context)) {
    $context: $context * 1px;
  }

  @return $pixels / $context * 1rem;
}
/**
* C O R E
*/
body {
  display: flex;
  min-width: 100vw;
  min-height: 100vh;
  background: $black-light;
  border-left: $izeOrangeBorder solid $orange;
  border-right: $izeOrangeBorder solid $orange;
  color: $grey;
  font-family: Qanelas, sans-serif;
  position: relative;
  overflow-x: hidden;
  &:before,
  &:after {
    content: "";
    position: absolute;
    background: $orange;
    width: 50vw;
    height: 100vh;
    z-index: 10000;
    @include trs(0.5s);
  }
  &:before {
    top: 0;
    left: 0;
    width: 0;
  }
  &:after {
    top: 0;
    right: 0;
    width: 0;
  }
  #logoLoader {
    position: fixed;
    top: -100px;
    left: 50vw;
    width: 100px;
    height: 35px;
    transform: translate(-50%, -50%);
    z-index: 999999999;
    background: url("../img/sophonic-logo.svg") no-repeat center center;
    background-size: contain;
    opacity: 0;
    display: block;
    @include trs(0.5s);
    animation: 2s fadeInLoop infinite;
  }
  &.loading {
    &:before {
      width: 50vw;
    }
    &:after {
      width: 50vw;
    }
    #logoLoader {
      opacity: 1;
      top: 50vh;
    }
  }
}
#popupDesktop {
  display: none;
}
#popupInMenu {
  display: flex;
  flex-direction: column;
  margin: 0 0 0 20px;
  padding: 15px;
  @include trs(0.2s);
  // &:hover {
  // }
  a {
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    img {
      margin-left: 10px;
      width: 50px;
      height: 50px;
      background: $black-light;
      border-radius: 1000px;
      padding: 10px;
    }
    p {
      color: $black-light;
      font-size: 0.8em;
      text-align: center;
      width: 70px;
      word-break: keep-all;
    }
    &:before {
      content: none;
    }
  }
}
#app {
  overflow: hidden;
  width: 100%;
  min-height: 100vh;
  position: relative;
}

#loader {
  text-align: center;
  color: #000000;
  position: absolute;
  z-index: 9999999999999999999;
  opacity: 0;
  @include trs(0.5s);
  &.loading {
    opacity: 1;
  }
  animation: fadeIn forwards 0.5s ease-in-out;
}
main.App {
  overflow: hidden;
  min-height: 100vh;
  > div:first-of-type {
    height: 100%;
  }
}
ul,
li,
a,
button {
  color: $grey;
  list-style-type: none;
  text-decoration: none;
  padding: 0;
}
form {
  label {
    font-size: rem(14);
    font-weight: 900;
    letter-spacing: 4.1px;
    text-transform: uppercase;
    color: $black-light;
    background: $grey;
    display: table;
    padding: 5px;
    margin-top: 15px;
    position: relative;
    &:first-of-type {
      margin-top: 0;
    }
  }
  input:not([type="submit"]),
  textarea {
    color: $grey;
    background: $black-middle;
    border: solid 1px $grey;
    border-radius: 0;
    padding: 15px;
    width: 100%;
    @include trs(0.3s);
    &:focus,
    &:active {
      border-color: $orange;
      outline: none;
    }
  }
  .submit {
    display: inline-flex;
    position: relative;
    @include trs(0.3s);
    input[type="submit"] {
      border: none;
      background: transparent;
      padding: 0;
      opacity: 1;
      color: $grey;
      @include trs(0.3s);
    }
    &.sent {
      pointer-events: none;
      opacity: 0;
    }
  }

  textarea {
    min-height: 200px;
  }
  #messageInfo,
  #mce-responses,
  #mce-error-response,
  #mce-success-response {
    display: block;
    margin-top: 15px;
    text-transform: uppercase;
    font-weight: 900;
    letter-spacing: 0.2em;
    color: $black-middle;
    background: $grey;
    max-height: 0px;
    overflow: hidden;
    @include trs(0.2s);
    &.message {
      max-height: 300px;
    }
    div {
      padding: 10px 10px;
    }
  }
}
a:not(.postLink):not(.logoLink),
.submit {
  font-weight: 900;
  font-size: 1em;
  &:before {
    content: "";
    display: inline-block;
    margin-right: 1em;
    margin-bottom: -0.1em;
    width: 1em;
    height: 1em;
    border: solid 1px $black;
    background-color: $black-light;
    @include trs(0.5s);
  }
  &:hover:before,
  &:focus:before,
  &:active:before {
    box-shadow: 0 0 6px 2px #e3bc6f80;
    border: solid 1px $orange;
    background-color: #ffd072;
  }
}
#listenButtonContainer {
  flex-basis: 100%;
  display: flex;
  justify-content: center;
  padding: 15px;
  background: $grey;
  .listenButton {
    display: flex;
    background: #dfded7;
    > a {
      color: $black-light;
      font-weight: 900;
      font-size: 1em;
      text-transform: uppercase;
      position: relative;
      letter-spacing: 0.15em;
      margin: 25px 80px 25px 30px;

      &:after,
      &:before {
        content: "";
        position: absolute;
        right: -60px;
        top: -13px;
        width: 40px;
        height: 40px;
        margin: 0;
        @include trs(0.5s);
        @include trs(0.2s, transform);
      }
      &:after {
        content: "";
        // box-shadow: inset 0 0 10px 2px rgb(255, 206, 106);
        // border: solid 3px rgb(255, 206, 106);
        background: url("../img/play.png") no-repeat center center;
        background-color: $orange;
        transform: translateY(-7px);
        top: -11px;
      }
      &:before {
        border: solid 2px rgb(0, 0, 0);
        right: -62px;
        width: 44px;
        height: 44px;
        background-color: #e59800;
        @include trs(0.3s, transform);
      }
      &:hover:after,
      &:focus:after,
      &:active:after,
      &:hover:before,
      &:focus:before,
      &:active:before {
        box-shadow: inset 0 0 10px 2px rgb(255, 206, 106), 0 0 6px 2px #e3bc6f80;
        background-color: $orange;
        transform: translateY(0px);
      }
    }
  }
}

.titleContainer {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.title {
  font-size: rem(30);
  width: 100%;
  left: 0;
  height: 100px;
  position: relative;
  display: block;
  h1 {
    position: absolute;
    z-index: -1;
    width: auto;
    margin: 0;
    text-transform: uppercase;
    font-weight: 900;
    letter-spacing: 2px;
    color: transparent;
    -webkit-text-stroke: 1px $grey;
    word-break: keep-all;
    white-space: nowrap;
    &:first-child {
      animation: 20s slideTitle linear infinite alternate;
    }

    &:last-child {
      animation: 20s slideTitle linear infinite alternate-reverse;
      top: 1.1em;
      transform: rotate(180deg);
    }
  }
}
.page:not(#home) {
  padding: 15px;
  position: relative;
  .content {
    margin-top: 9em;
    h2 {
      font-size: rem(40);
      font-weight: 500;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      position: relative;
      &:before {
        content: "";
        position: absolute;
        z-index: -1;
        left: -65px;
        top: 0.5em;
        width: 50px;
        border-top: solid 1px $grey;
      }
    }
  }
}
/**
* H E A D E R
*/
header#header {
  position: relative;
  z-index: 10;
  margin: auto;
  display: flex;
  flex-direction: column;
  margin-top: -35vh;
  @include trs(0.5s);
  &.open {
    margin-top: 0;
  }
  #menu {
    display: flex;
    background-color: $grey;
    background-image: url(../img/noise_bg.png);
    background-repeat: repeat;
    background-blend-mode: darken;
    height: 35vh;
    h1 {
      display: none;
    }
    nav {
      height: 100%;
      margin-top: -15px;
      padding: 30px 0;
      overflow: hidden;
      ul {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        .menuItem {
          padding: 10px 15px;
          position: relative;
          &:before {
            content: "";
            width: 100%;
            margin-left: -100%;
            bottom: 50%;
            border-bottom: 1px solid $black-light;
            position: absolute;
          }
          &.active .menuLink {
            pointer-events: none;
            &:before {
              box-shadow: 0 0 6px 2px #e3bc6f80;
              border: solid 1px $orange;
              background-color: #ffd072;
            }
          }

          .menuLink {
            color: $black-light;
            text-transform: uppercase;
            letter-spacing: 0.2em;
            padding: 15px;
          }
        }
      }
    }
  }
  #logo {
    .logoLink {
      font-size: 0;
    }
    img {
      background: $grey;
      padding: 0px 13px;
      width: 100px;
      height: 35px;
    }
  }
  #burgerMenu {
    width: 20px;
    height: 20px;
    position: absolute;
    z-index: 999;
    bottom: 0;
    right: 15px;
    display: flex;
    flex-direction: column;
    .burgerStroke {
      width: 100%;
      height: 1px;
      position: relative;
      right: 0;
      border: solid 1px $grey;
      @include trs(0.4s);
      &:nth-child(2) {
        top: 4px;
      }
      &:nth-child(3) {
        top: 8px;
        width: 80%;
        right: -20%;
      }
    }
    &.open {
      .burgerStroke {
        &:nth-child(1) {
          transform: rotate(-45deg);
          transform-origin: center center;
          top: 6px;
        }
        &:nth-child(2) {
          width: 0%;
          border-width: 0px;
        }
        &:nth-child(3) {
          width: 100%;
          right: 0;
          top: 3px;
          transform: rotate(45deg);
          transform-origin: center center;
        }
      }
    }
  }
  .credit {
    color: $black-light;
    position: absolute;
    bottom: 34px;
    right: 0;
    text-align: center;
    font-size: 0.5em;
    padding: 5px;
    margin: 15px;
    border: solid 1px $black-light;
    opacity: 0.5;
    a {
      font-weight: bolder;
      text-transform: uppercase;
      color: $black-light;
      &:before,
      &:after {
        content: none;
      }
    }
  }
}
/**
* F O O T E R
*/
footer#footer {
  margin: auto;
  display: flex;
  justify-content: flex-end;
  font-size: rem(13);
  font-weight: 900;
  letter-spacing: 5.7px;
  text-align: right;
  color: var(--grey);
  text-transform: uppercase;

  ul {
    margin-right: 15px;
    li {
      padding: 17px 0;
      margin-right: 15px;
      a {
        position: relative;
        &:before {
          content: none;
        }
        &:after {
          content: "";
          width: 40px;
          display: block;
          border-top: solid 1px;
          position: absolute;
          z-index: -1;
          right: -55px;
          top: 0.5em;
        }
      }
    }
  }
}
.accordion {
  .content {
    max-height: 0px;
    padding: 15px;
    padding-top: 0;
    padding-bottom: 0;
    overflow: hidden;
    @include trs(0.3s);
  }
  &.open {
    .content {
      max-height: 800px;
    }
  }
}

/**
*   H O M E
*/
#postContainer {
  > div:not(#linksContainer) {
    position: relative;
    width: 100%;
    height: 100%;
    > div.post {
      margin: auto;
      top: 0;
      left: 0;
      bottom: 0;
      right: unset;
      // overflow: hidden;
      &:nth-child(2) {
        z-index: -1;
      }
      @include trs(0.2s, opacity);
      transition-delay: 0.6s;
      .thumbnail {
        @include trs(1s);
        transition-delay: 0;
        overflow: hidden;
      }
      .what {
        @include trs(0.3s);
        transition-delay: 0.3s;
        order: 2;
        overflow: hidden;
        a {
          color: $black-light;
          &:before {
            content: none;
          }
        }
      }
      #listenButtonContainer {
        @include trs(0.3s);
        overflow: hidden;
        //
      }
      .title h1 {
        @include trs(1s);
        transition-delay: 0.3s;
      }
      &.post-enter {
        .title h1 {
          // max-height: 0;
          opacity: 0;
        }
        .thumbnail {
          // max-height: 0;
          // padding-top: 0;
          // padding-bottom: 0;
          opacity: 0;
        }
        .what {
          // max-height: 0;
          opacity: 0;
        }
        #listenButtonContainer {
          transition-delay: 0.8s;
          // opacity: 0;
        }
      }
      &.post-enter-active {
        .title h1 {
          max-height: 200px;
          opacity: 1;
        }
        .thumbnail {
          max-height: 600px;
          padding-top: 4%;
          padding-bottom: 4%;
          opacity: 1;
        }
        .what {
          max-height: 600px;
          opacity: 1;
        }
        #listenButtonContainer {
          transition-delay: 0.8s;
          opacity: 1;
        }
      }
      &.post-exit {
        z-index: -1;
        position: absolute;
        .title h1 {
          max-height: 200px;
          opacity: 1;
        }
        .thumbnail {
          max-height: 600px;
          padding-top: 4%;
          padding-bottom: 4%;
          opacity: 1;
        }
        .what {
          max-height: 600px;
          opacity: 1;
        }
        #listenButtonContainer {
          opacity: 1;
        }
      }
      &.post-exit-active {
        z-index: -1;
        position: absolute;
        .title h1 {
          // max-height: 0px;
          opacity: 0;
        }
        .thumbnail {
          // max-height: 0;
          // padding-top: 0;
          // padding-bottom: 0;
          opacity: 0;
        }
        .what {
          // max-height: 0;
          opacity: 0;
        }
        #listenButtonContainer {
          // opacity: 0;
        }
      }
      &.post-enter-done {
        .title h1 {
          max-height: 200px;
          opacity: 1;
        }
        .thumbnail {
          max-height: 600px;
          padding-top: 4%;
          padding-bottom: 4%;
          opacity: 1;
        }
        .what {
          max-height: 600px;
          opacity: 1;
        }
        #listenButtonContainer {
          opacity: 1;
        }
      }
    }
  }
}
.postItem {
  position: relative;
  margin-top: 10px;
  .title {
    font-size: rem(30);
    width: 100%;
    left: 0;
    position: relative;
    display: block;
    h1 {
      position: absolute;
      z-index: -1;
      width: auto;
      margin: 0;
      text-transform: uppercase;
      font-weight: 900;
      letter-spacing: 2px;
      color: transparent;
      -webkit-text-stroke: 1px $grey;
      word-break: keep-all;
      white-space: nowrap;
      // animation: 10s slideTitle linear infinite;
      &:last-child {
        top: 1.1em;
        transform: rotate(180deg);
      }
    }
  }
  .row {
    margin: 0 15px;
    margin-top: 67px;
    .thumbnail {
      padding: 15px;
      border: solid 1px $grey;
      background: $black-middle;
      img {
        width: 100%;
      }
    }
    .accordion {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: 0;
      margin-top: -1px;
      h2 {
        border: solid 1px $grey;
        text-align: center;
        text-transform: uppercase;
        font-size: rem(14);
        font-weight: 900;
        letter-spacing: 4.1px;
        padding: 3px 0;
        margin: 0;
        background: $black-middle;
      }
      .content {
        margin: 0;
        background: $grey;
        color: $black-light;
        h3 {
          font-size: rem(24);
          font-weight: 500;
          letter-spacing: 2.6px;
          text-transform: uppercase;
        }
        li {
          margin: 0;
          padding: 0;
          margin-top: 1.1em;
          margin-bottom: 1.1em;
          a {
            position: relative;
            font-size: rem(11);
            text-transform: uppercase;
            font-weight: 900;
            letter-spacing: 4.8px;
            color: $black-light;
            &:before {
              width: 15px;
              height: 15px;
              top: 1.3em;
              margin-top: 1.1em;
              margin-bottom: 1.1em;
              position: relative;
            }
          }
        }
        .tracks li {
          color: $black-light;
          position: relative;
          margin-left: 10%;
          text-transform: uppercase;
          letter-spacing: 0.2em;
          font-weight: bolder;
          &:before {
            content: "";
            position: absolute;
            width: 100%;
            border-bottom: 1px solid $black-light;
            left: -102%;
            top: 0.6em;
          }
          span {
            font-weight: bolder;
            text-transform: uppercase;
            letter-spacing: 0.2em;
            position: relative;
            margin-left: 15px;
            &:before {
              content: "";
              position: absolute;
              width: 10px;
              border-bottom: 1px solid $black-light;
              left: -15px;
              top: 0.6em;
            }
          }
        }
      }
    }
  }
}
/**
* LINKS
*/
@keyframes strokeFill {
  0% {
    stroke-dashoffset: -100%;
  }
  100% {
    stroke-dashoffset: 0%;
  }
}
@keyframes strokeDash {
  0% {
    stroke-dashoffset: 0%;
  }

  100% {
    stroke-dashoffset: 120%;
  }
}
#linksContainer {
  display: flex;
  flex-direction: column;
  .polylineContainer {
    width: 100%;
    height: 100px;
    display: block;
    #svg {
      width: 100%;
      height: 100%;
      polyline {
        transform: translate(0.5px, 0.5px);
        stroke: $grey;
        stroke-width: 1px;
        fill: none;
        stroke-dasharray: 100%;
        animation: strokeFill 2s forwards ease-in-out;
        @include trs(0.5s);
        &.active {
          stroke-dasharray: 2%;
          animation: strokeDash 10s infinite linear;
        }
      }
    }
  }

  ul.anchorContainer,
  ul.selectContainer {
    display: inline-flex;
    justify-content: space-around;
    li {
      width: 100%;
      padding: 0;
      button {
        background: transparent;
        font-size: 0;
      }
    }
  }

  ul.anchorContainer {
    margin: 0;
    justify-content: center;
    li {
      width: 10px;
      padding: 0;
    }
  }
  ul.selectContainer {
    button {
      border: none;
      margin-top: 20px;
      padding: 5px;
      @include trs(0.5s);
      &:focus {
        outline: none;
        background: $black-light;
      }
      canvas {
        width: 100%;
        transform: rotate(-90deg);
        cursor: pointer;
      }
    }
  }
}
/**
* B I O
*/
#bio {
  .imgBGContainer {
    margin-left: -40px;
    margin-right: -15px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    figcaption {
      position: absolute;
      bottom: 10px;
      left: 10px;
      font-size: 0.7rem;
    }
  }
}
/**
* C O N T A C T
*/
#contact {
  .label {
    padding-left: 30px;
    border-left: solid 1px $grey;
    p {
      margin: 0.2em 0;
    }
    a {
      position: relative;
      font-weight: 500;
      &:after {
        content: "";
        position: absolute;
        z-index: -1;
        left: -25px;
        top: 0.5em;
        width: 15px;
        border-top: solid 1px $grey;
      }
    }
  }
}
.contactForm {
  .formContainer {
    padding: 0 30px;
    border-left: solid 1px $grey;
    form {
      label {
        font-size: rem(14);
        font-weight: 900;
        letter-spacing: 4.1px;
        text-transform: uppercase;
        color: $black-light;
        background: $grey;
        display: table;
        padding: 5px;
        margin-top: 15px;
        position: relative;
        &:before {
          content: "";
          position: absolute;
          z-index: -1;
          left: -30px;
          top: 50px;
          width: 25px;
          border-top: solid 1px $grey;
        }
        &:first-of-type {
          margin-top: 0;
        }
      }
      input,
      textarea {
        color: $grey;
        background: $black-middle;
        border: solid 1px $grey;
        border-radius: 0;
        padding: 15px;
        width: 100%;
        @include trs(0.3s);
        &:focus,
        &:active {
          border-color: $orange;
          outline: none;
        }
      }
      .submit {
        display: inline-flex;
        margin-top: 30px;
        position: relative;
        @include trs(0.3s);
        input[type="submit"] {
          border: none;
          background: transparent;
          padding: 0;
          opacity: 1;
          @include trs(0.3s);
        }
        &.sent {
          pointer-events: none;
          opacity: 0;
        }
        &:after {
          content: "";
          position: absolute;
          z-index: -1;
          left: -30px;
          top: 7px;
          width: 25px;
          border-top: solid 1px $grey;
        }
      }

      textarea {
        min-height: 200px;
      }
      #messageInfo {
        display: block;
        margin-top: 15px;
        text-transform: uppercase;
        font-weight: 900;
        letter-spacing: 0.2em;
        color: $black-middle;
        background: $grey;
        max-height: 0px;
        overflow: hidden;
        @include trs(0.2s);
        &.message {
          max-height: 300px;
        }
        div {
          padding: 10px 10px;
        }
      }
    }
  }
}
/**
* D A T E S
*/
#dates {
  .bit-widget-container {
    border: solid 1px $grey;
    .bit-logo-link,
    .bit-details {
      &:before {
        content: none;
      }
    }
    .bit-top-track-button {
      display: none;
    }
  }
  .bit-nav-bar-container {
    background: $grey;
    color: $black-light;
    border-bottom: solid 1px $black-light;
    .bit-nav-bar {
      padding: 5px 0 10px 0;
    }
  }
  .bit-upcoming-events {
    margin: 0;
    border: none;
  }
  .bit-event {
    padding: 0 15px 15px 15px;
    display: flex;
    flex-direction: column;
    border: none;
    .bit-event-buttons {
      margin: 0 -15px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      order: -1;
      background: $grey;
      padding-right: 10px;
      div {
        margin: 0;
        flex-basis: unset;
        a {
          border: none;
          background: transparent;
          color: $black-middle;
          &:before {
            margin-right: 0.7em;
            margin-left: 0.7em;
          }
        }
      }
    }
    a.bit-details {
      display: flex;
      flex-direction: column;
      margin-top: -2em;
      .bit-date {
        order: 1;
        // margin-top: -2em;
        text-transform: uppercase;
        font-weight: 900;
        color: $black-light;
      }
      .bit-location {
        order: 2;
        color: $grey;
        margin-top: 25px;
      }
      .bit-venue {
        order: 3;
        color: $grey;
        font-weight: 100;
      }
    }
  }
}
/**
* P A G E   V I D E O S
*/
#videos.page:not(#home) {
  width: 100%;
  .content {
    .row {
      .col-md-12 {
        width: 100%;
        margin-bottom: 30px;
        padding-bottom: 56.25%;
        height: 0;
        position: relative;
        iframe {
          width: 100%;
          height: 100%;
          position: absolute;
        }
      }
    }
    h2 {
      margin-left: 30px;
      &:before {
        left: -135px;
        width: 120px;
      }
    }
  }
}
/**
* P A G E   T R A N S I T I O N
*/
main.App {
  position: relative;
}
.page {
  transition: all 0.5s ease-in-out;
  width: 100%;
}
.page-enter {
  opacity: 0;
  transition-delay: 0.5s;
}
.page-enter-active {
  opacity: 1;
}
.page-exit {
  opacity: 1;
  position: absolute !important;
  top: 0;
  left: 0;
}
.page-exit-active {
  opacity: 0;
  // top: 120%;
  // left: 0;
}

/**
* Responsive above mobile
*/
@import "responsive";
