// Before that is mobile
// 768px
@media only screen and (min-width: 54em) {
  :root {
    font-size: 14px;
  }
  main.App {
    margin-top: -35px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }
  body {
    border-left: 35px solid $orange;
    border-right: 35px solid $orange;
    background: linear-gradient(31deg, $black, $black-light);
    overflow: hidden;
    #logoLoader {
      position: fixed;
      top: -100px;
      left: 50vw;
      width: 100px;
      height: 35px;
      transform: translate(-50%, -50%);
      z-index: 999999999;
      background: url("../img/sophonic-logo.svg") no-repeat center center;
      background-size: contain;
      opacity: 0;
      display: block;
      @include trs(0.5s);
      &:before {
        content: "";
        position: absolute;
        left: -20px;
        top: 0;
        width: 100px;
        height: 35px;
        background: $orange;
        box-shadow: 0 0 10px 0 $orange;
        animation: logoLoaderMask 3s linear infinite;
      }
    }
    &.loading {
      &:before {
        width: 50vw;
      }
      &:after {
        width: 50vw;
      }
      #logoLoader {
        opacity: 1;
        top: 50vh;
      }
    }
  }
  [type="submit"] {
    cursor: pointer;
  }
  .title {
    transform: rotate(90deg) translateY(-30vw);
    font-size: rem(80);
    z-index: 1;
    position: absolute;
    top: 0;
    max-width: 100vh;
    height: 100%;
    overflow: hidden;
    pointer-events: none;
    h1 {
      width: 320%;
    }
  }
  #home {
    position: relative;
    height: 100%;
    .content {
      height: 100%;
    }
  }
  .page:not(#home) {
    top: 50%;
    height: 100%;
    transform: translateY(-50%);
    flex-direction: column;
    justify-content: center;
    padding-left: 65px;
    display: flex;

    .content {
      margin-top: 0;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
    }
  }
  #listenButtonContainer {
    justify-content: flex-end;
    margin-top: 0;
    border-top: solid 2px $black-middle;
    padding: 0;
    background: transparent;
  }
  /**
  * H E A D E R
  */
  header#header {
    #menu {
      display: flex;
      h1 {
        display: block;
      }
      nav {
        padding-left: 70px;
      }
      h1 {
        background-color: #222222aa;
        color: transparent;
        text-shadow: 1px 1px 4px #dfded7;
        -webkit-background-clip: text;
        -moz-background-clip: text;
        background-clip: text;
        font-family: Qanelas;
        font-size: 6vw;
        font-weight: normal;
        letter-spacing: 0.5em;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-70%);
        margin: 0;
      }
    }
    #burgerMenu {
      cursor: pointer;
      .burgerStroke {
        background: $grey;
        @include trs(0.4s);
      }
      &:hover {
        .burgerStroke {
          width: 100%;
        }
      }
    }
  }
  /**
  * F O O T E R
  */
  footer#footer {
    position: absolute;
    bottom: 0;
    right: 0;
    ul li {
      padding: 5px 0;
    }
    .footerLink {
      a {
        right: 0;
        @include trs(0.3s);
        &:hover {
          right: 15px;
        }
      }
    }
  }
  /**
  * H O M E 
  */
  #home {
    width: 100%;
    div#postContainer {
      display: flex;
      flex-direction: row-reverse;
      height: 100%;
      // height: calc(100vh - 30px);
      justify-content: flex-end;
    }
    #linksContainer {
      flex-direction: row-reverse;
      flex-basis: 20%;
      height: 100vh;
      .anchorContainer {
        display: flex;
        flex-direction: column;
        margin-left: auto;
        li {
          width: 0px;
          height: 20px;
          margin-left: auto;
        }
      }
      .polylineContainer {
        width: 100%;
        height: 100vh;
      }
      .selectContainer {
        flex-direction: column;
        margin: 0;
        height: 100vh;
        li {
          width: 90px;
          margin-top: 1px;
          canvas {
            transform: rotate(0);
          }
        }
        button {
          margin-top: unset;
        }
      }
    }
    .postItem {
      max-width: 1000px;
      margin: 0 auto;
      height: 100vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .title {
        transform: rotate(90deg);
        font-size: rem(80);
        z-index: -1;
        position: absolute;
      }
      .row {
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 0;
        .accordion {
          h2 {
            border-left: none;
            border-bottom: none;
          }
          .content {
            max-height: 800px;
          }
        }
        .thumbnail {
          flex-basis: 50%;
          padding: 4%;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
        .what {
          flex-basis: 50%;
          margin-top: 0;
          border-left: 0px;
          order: unset !important;
          .content {
            height: 100%;
            // border-right: 1px solid $black-light;
          }
        }
      }
    }
  }
  #postContainer {
    > div:not(#linksContainer) {
      > div.post {
        position: absolute;
        .thumbnail {
          @include trs(1s);
          transition-delay: 0;
          overflow: hidden;
        }
        .what {
          @include trs(1s);
          transition-delay: 0.3s;
          order: 2;
          overflow: hidden;
        }
        #listenButtonContainer {
          @include trs(0.3s);
          overflow: hidden;
          //
        }
        .title h1 {
          @include trs(1s);
          transition-delay: 0.3s;
        }
        &.post-enter {
          .title h1 {
            max-height: 0;
            opacity: 0;
          }
          .thumbnail {
            max-height: 0;
            padding-top: 0;
            padding-bottom: 0;
            opacity: 0;
          }
          .what {
            max-height: 0;
            opacity: 0;
          }
          #listenButtonContainer {
            transition-delay: 0.8s;
            opacity: 0;
          }
        }
        &.post-enter-active {
          .title h1 {
            max-height: 200px;
            opacity: 1;
          }
          .thumbnail {
            max-height: 600px;
            padding-top: 4%;
            padding-bottom: 4%;
            opacity: 1;
          }
          .what {
            max-height: 600px;
            opacity: 1;
          }
          #listenButtonContainer {
            transition-delay: 0.8s;
            opacity: 1;
          }
        }
        &.post-exit {
          z-index: -1;
          position: absolute;
          .title h1 {
            max-height: 200px;
            opacity: 1;
          }
          .thumbnail {
            max-height: 600px;
            padding-top: 4%;
            padding-bottom: 4%;
            opacity: 1;
          }
          .what {
            max-height: 600px;
            opacity: 1;
          }
          #listenButtonContainer {
            opacity: 1;
          }
        }
        &.post-exit-active {
          z-index: -1;
          position: absolute;
          .title h1 {
            max-height: 0px;
            opacity: 0;
          }
          .thumbnail {
            max-height: 0;
            padding-top: 0;
            padding-bottom: 0;
            opacity: 0;
          }
          .what {
            max-height: 0;
            opacity: 0;
          }
          #listenButtonContainer {
            opacity: 0;
          }
        }
        &.post-enter-done {
          .title h1 {
            max-height: 200px;
            opacity: 1;
          }
          .thumbnail {
            max-height: 600px;
            padding-top: 4%;
            padding-bottom: 4%;
            opacity: 1;
          }
          .what {
            max-height: 600px;
            opacity: 1;
          }
          #listenButtonContainer {
            opacity: 1;
          }
        }
      }
    }
  }
  /**
  * B   I   O
  */
  #bio {
    display: flex;
    padding-left: 65px !important;
    max-width: 500px;
    > div:nth-child(2) {
      max-width: 500px;
    }
    .imgBGContainer {
      position: absolute;
      height: 100vh;
      width: 100vw;
      margin: 0;
      padding: 0;
      top: 0;
      left: 0;
      z-index: -10;
      overflow: hidden;
    }
  }

  /**
*   C O N T A C T
*/
  #contact.page:not(#home) {
    display: flex;
    width: 80%;
    padding-left: 85px;
    .content {
      > div:not(.titleContainer) {
        width: 50%;
      }
      flex-direction: row;
      div:not(.title):not(.contactForm) {
        h2 {
          &:before {
            left: unset;
            margin-left: calc(-100% - 15px);
            width: 100%;
          }
        }
      }
      .contactForm {
        h2 {
          &:before {
            content: none;
          }
        }
        div.submit {
          cursor: pointer;
          input {
            cursor: pointer;
          }
        }
      }
    }
  }
  /**
    *   D A T E S
    */
  #dates.page:not(#home) {
    .title {
      z-index: -1 !important;
    }
    display: flex;
    flex-direction: column;
    width: 90%;
    padding-left: 85px;
    div:not(.title) {
      h2 {
        &:before {
          left: unset;
          margin-left: calc(-100% - 15px);
          width: 100%;
        }
      }
    }
    .bit-widget-container {
      // margin-left: -25px;
      .bit-widget {
        padding: 0;

        .bit-event {
          border-bottom: 1px solid $grey;
          padding: 0;
          flex-direction: row-reverse;
          background: $black-middle;
          .bit-details {
            flex-direction: row;
            margin-top: 0;
          }
          div {
            height: 100%;
            .bit-date {
              margin: 0;
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              text-align: center;
              background: $grey;
              border-top: 1px solid $black-light;
              min-width: 150px;
            }
          }
          .bit-location {
            margin: 0;

            padding-left: 15px;
            font-weight: 900;
          }
          .bit-venue {
            padding-left: 15px;
          }
          &:first-of-type {
            .bit-date {
              border-top: none;
            }
          }
        }
        .bit-event-buttons {
          margin: 0;
          background: transparent;
          .bit-button {
            color: $grey;
          }
        }
      }
    }
  }
  /**
* P A G E   V I D E O S
*/
  #videos.page:not(#home) {
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 0;
    backface-visibility: hidden;
    will-change: overflow;
    .content {
      overflow: auto;
      height: 100%;
      &::-webkit-scrollbar {
        display: none;
        background: transparent;
      }
      padding-left: 85px;
      .row {
        width: 80%;
        .col-md-12 {
          position: relative;
          overflow: hidden;
          padding-bottom: 56.25%;
          iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 0;
          }
        }
      }
      h2 {
        &:before {
          left: -135px;
          width: 120px;
        }
      }
    }
  }
  /**
* P A G E   T R A N S I T I O N
*/
  main.App {
    position: relative;
  }
  .page {
    transition: all 0.5s ease-in-out;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
  }
  .page-enter {
    opacity: 0;
    transition-delay: 0.5s;
  }
  .page-enter-active {
    opacity: 1;
  }
  .page-exit {
    opacity: 1;
    position: absolute;
    top: 0;
    left: 0;
  }
  .page-exit-active {
    opacity: 0;
    // top: 120%;
    // left: 0;
  }
  #popupDesktop {
    display: block;
    position: absolute;
    bottom: -50vh;
    right: -86px;
    background: $grey;
    padding: 15px;
    padding-right: 100px;
    border-top-left-radius: 1000px;
    border-bottom-left-radius: 1000px;
    box-shadow: 0 0 6px 2px #36363680;
    @include trs(0.2s);
    &:hover {
      right: -14px;
    }
    a {
      text-transform: uppercase;
      display: flex;
      img {
        width: 100px;
        height: 100px;
        background: $black-light;
        border-radius: 1000px;
        padding: 10px;
      }
      p {
        position: absolute;
        color: $black-light;
        transform: rotate(-90deg) translate(-8px, 90px);
        font-size: 1.2em;
        text-align: center;
        width: 100px;
      }
      &:before {
        content: none;
      }
    }
  }
}
// 992px
@media only screen and (min-width: 1024px) {
}
// 1150px
@media only screen and (min-width: 1200px) {
}
