//
// sanitize.css
//
/* sanitize.css v7.0.3 | CC0 License | github.com/csstools/sanitize.css */

/* Document
 * ========================================================================== */

/**
 * 1. Remove repeating backgrounds in all browsers (opinionated).
 * 2. Add border box sizing in all browsers (opinionated).
 */

 *,
 ::before,
 ::after {
   background-repeat: no-repeat; /* 1 */
   box-sizing: border-box; /* 2 */
 }
 
 /**
  * 1. Add text decoration inheritance in all browsers (opinionated).
  * 2. Add vertical alignment inheritance in all browsers (opinionated).
  */
 
 ::before,
 ::after {
   text-decoration: inherit; /* 1 */
   vertical-align: inherit; /* 2 */
 }
 
 /**
  * 1. Use the default cursor in all browsers (opinionated).
  * 2. Use the default user interface font in all browsers (opinionated).
  * 3. Correct the line height in all browsers.
  * 4. Use a 4-space tab width in all browsers (opinionated).
  * 5. Prevent adjustments of font size after orientation changes in
  *    IE on Windows Phone and in iOS.
  * 6. Breaks words to prevent overflow in all browsers (opinionated).
  */
 
 html {
   cursor: default; /* 1 */
   font-family:
     system-ui,
     /* macOS 10.11-10.12 */ -apple-system,
     /* Windows 6+ */ Segoe UI,
     /* Android 4+ */ Roboto,
     /* Ubuntu 10.10+ */ Ubuntu,
     /* Gnome 3+ */ Cantarell,
     /* KDE Plasma 5+ */ Noto Sans,
     /* fallback */ sans-serif,
     /* macOS emoji */ "Apple Color Emoji",
     /* Windows emoji */ "Segoe UI Emoji",
     /* Windows emoji */ "Segoe UI Symbol",
     /* Linux emoji */ "Noto Color Emoji"; /* 2 */
 
   line-height: 1.15; /* 3 */
   -moz-tab-size: 4; /* 4 */
   tab-size: 4; /* 4 */
   -ms-text-size-adjust: 100%; /* 5 */
   -webkit-text-size-adjust: 100%; /* 5 */
   word-break: break-word; /* 6 */
 }
 
 /* Sections
  * ========================================================================== */
 
 /**
  * Remove the margin in all browsers (opinionated).
  */
 
 body {
   margin: 0;
 }
 
 /**
  * Correct the font size and margin on `h1` elements within `section` and
  * `article` contexts in Chrome, Firefox, and Safari.
  */
 
 h1 {
   font-size: 2em;
   margin: 0.67em 0;
 }
 
 /* Grouping content
  * ========================================================================== */
 
 /**
  * 1. Add the correct sizing in Firefox.
  * 2. Show the overflow in Edge and IE.
  */
 
 hr {
   height: 0; /* 1 */
   overflow: visible; /* 2 */
 }
 
 /**
  * Add the correct display in IE.
  */
 
 main {
   display: block;
 }
 
 /**
  * Remove the list style on navigation lists in all browsers (opinionated).
  */
 
 nav ol,
 nav ul {
   list-style: none;
 }
 
 /**
  * 1. Use the default monospace user interface font
  *    in all browsers (opinionated).
  * 2. Correct the odd `em` font sizing in all browsers.
  */
 
 pre {
   font-family:
     /* macOS 10.10+ */ Menlo,
     /* Windows 6+ */ Consolas,
     /* Android 4+ */ Roboto Mono,
     /* Ubuntu 10.10+ */ Ubuntu Monospace,
     /* KDE Plasma 5+ */ Noto Mono,
     /* KDE Plasma 4+ */ Oxygen Mono,
     /* Linux/OpenOffice fallback */ Liberation Mono,
     /* fallback */ monospace; /* 1 */
 
   font-size: 1em; /* 2 */
 }
 
 /* Text-level semantics
  * ========================================================================== */
 
 /**
  * Remove the gray background on active links in IE 10.
  */
 
 a {
   background-color: transparent;
 }
 
 /**
  * Add the correct text decoration in Edge, IE, Opera, and Safari.
  */
 
 abbr[title] {
   text-decoration: underline;
   text-decoration: underline dotted;
 }
 
 /**
  * Add the correct font weight in Chrome, Edge, and Safari.
  */
 
 b,
 strong {
   font-weight: bolder;
 }
 
 /**
  * 1. Use the default monospace user interface font
  *    in all browsers (opinionated).
  * 2. Correct the odd `em` font sizing in all browsers.
  */
 
 code,
 kbd,
 samp {
   font-family:
     /* macOS 10.10+ */ Menlo,
     /* Windows 6+ */ Consolas,
     /* Android 4+ */ Roboto Mono,
     /* Ubuntu 10.10+ */ Ubuntu Monospace,
     /* KDE Plasma 5+ */ Noto Mono,
     /* KDE Plasma 4+ */ Oxygen Mono,
     /* Linux/OpenOffice fallback */ Liberation Mono,
     /* fallback */ monospace; /* 1 */
 
   font-size: 1em; /* 2 */
 }
 
 /**
  * Add the correct font size in all browsers.
  */
 
 small {
   font-size: 80%;
 }
 
 /*
  * Remove the text shadow on text selections in Firefox 61- (opinionated).
  * 1. Restore the coloring undone by defining the text shadow
  *    in all browsers (opinionated).
  */
 
 ::-moz-selection {
   background-color: #b3d4fc; /* 1 */
   color: #000; /* 1 */
   text-shadow: none;
 }
 
 ::selection {
   background-color: #b3d4fc; /* 1 */
   color: #000; /* 1 */
   text-shadow: none;
 }
 
 /* Embedded content
  * ========================================================================== */
 
 /*
  * Change the alignment on media elements in all browers (opinionated).
  */
 
 audio,
 canvas,
 iframe,
 img,
 svg,
 video {
   vertical-align: middle;
 }
 
 /**
  * Add the correct display in IE 9-.
  */
 
 audio,
 video {
   display: inline-block;
 }
 
 /**
  * Add the correct display in iOS 4-7.
  */
 
 audio:not([controls]) {
   display: none;
   height: 0;
 }
 
 /**
  * Remove the border on images inside links in IE 10-.
  */
 
 img {
   border-style: none;
 }
 
 /**
  * Change the fill color to match the text color in all browsers (opinionated).
  */
 
 svg:not([fill]) {
   fill: currentColor;
 }
 
 /**
  * Hide the overflow in IE.
  */
 
 svg:not(:root) {
   overflow: hidden;
 }
 
 /* Tabular data
  * ========================================================================== */
 
 /**
  * Collapse border spacing in all browsers (opinionated).
  */
 
 table {
   border-collapse: collapse;
 }
 
 /* Forms
  * ========================================================================== */
 
 /**
  * Inherit styling in all browsers (opinionated).
  */
 
 button,
 input,
 select,
 textarea {
   font-family: inherit;
   font-size: inherit;
   line-height: inherit;
 }
 
 /**
  * Remove the margin in Safari.
  */
 
 button,
 input,
 select {
   margin: 0;
 }
 
 /**
  * 1. Show the overflow in IE.
  * 2. Remove the inheritance of text transform in Edge, Firefox, and IE.
  */
 
 button {
   overflow: visible; /* 1 */
   text-transform: none; /* 2 */
 }
 
 /**
  * Correct the inability to style clickable types in iOS and Safari.
  */
 
 button,
 [type="button"],
 [type="reset"],
 [type="submit"] {
   -webkit-appearance: button;
 }
 
 /**
  * Correct the padding in Firefox.
  */
 
 fieldset {
   padding: 0.35em 0.75em 0.625em;
 }
 
 /**
  * Show the overflow in Edge and IE.
  */
 
 input {
   overflow: visible;
 }
 
 /**
  * 1. Correct the text wrapping in Edge and IE.
  * 2. Correct the color inheritance from `fieldset` elements in IE.
  */
 
 legend {
   color: inherit; /* 2 */
   display: table; /* 1 */
   max-width: 100%; /* 1 */
   white-space: normal; /* 1 */
 }
 
 /**
  * 1. Add the correct display in Edge and IE.
  * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
  */
 
 progress {
   display: inline-block; /* 1 */
   vertical-align: baseline; /* 2 */
 }
 
 /**
  * Remove the inheritance of text transform in Firefox.
  */
 
 select {
   text-transform: none;
 }
 
 /**
  * 1. Remove the margin in Firefox and Safari.
  * 2. Remove the default vertical scrollbar in IE.
  * 3. Change the resize direction on textareas in all browsers (opinionated).
  */
 
 textarea {
   margin: 0; /* 1 */
   overflow: auto; /* 2 */
   resize: vertical; /* 3 */
 }
 
 /**
  * Remove the padding in IE 10-.
  */
 
 [type="checkbox"],
 [type="radio"] {
   padding: 0;
 }
 
 /**
  * 1. Correct the odd appearance in Chrome and Safari.
  * 2. Correct the outline style in Safari.
  */
 
 [type="search"] {
   -webkit-appearance: textfield; /* 1 */
   outline-offset: -2px; /* 2 */
 }
 
 /**
  * Correct the cursor style of increment and decrement buttons in Safari.
  */
 
 ::-webkit-inner-spin-button,
 ::-webkit-outer-spin-button {
   height: auto;
 }
 
 /**
  * Correct the text style of placeholders in Chrome, Edge, and Safari.
  */
 
 ::-webkit-input-placeholder {
   color: inherit;
   opacity: 0.54;
 }
 
 /**
  * Remove the inner padding in Chrome and Safari on macOS.
  */
 
 ::-webkit-search-decoration {
   -webkit-appearance: none;
 }
 
 /**
  * 1. Correct the inability to style clickable types in iOS and Safari.
  * 2. Change font properties to `inherit` in Safari.
  */
 
 ::-webkit-file-upload-button {
   -webkit-appearance: button; /* 1 */
   font: inherit; /* 2 */
 }
 
 /**
  * Remove the inner border and padding of focus outlines in Firefox.
  */
 
 ::-moz-focus-inner {
   border-style: none;
   padding: 0;
 }
 
 /**
  * Restore the focus outline styles unset by the previous rule in Firefox.
  */
 
 :-moz-focusring {
   outline: 1px dotted ButtonText;
 }
 
 /* Interactive
  * ========================================================================== */
 
 /*
  * Add the correct display in Edge and IE.
  */
 
 details {
   display: block;
 }
 
 /*
  * Add the correct styles in Edge, IE, and Safari.
  */
 
 dialog {
   background-color: white;
   border: solid;
   color: black;
   display: block;
   height: -moz-fit-content;
   height: -webkit-fit-content;
   height: fit-content;
   left: 0;
   margin: auto;
   padding: 1em;
   position: absolute;
   right: 0;
   width: -moz-fit-content;
   width: -webkit-fit-content;
   width: fit-content;
 }
 
 dialog:not([open]) {
   display: none;
 }
 
 /*
  * Add the correct display in all browsers.
  */
 
 summary {
   display: list-item;
 }
 
 /* Scripting
  * ========================================================================== */
 
 /**
  * Add the correct display in IE 9-.
  */
 
 canvas {
   display: inline-block;
 }
 
 /**
  * Add the correct display in IE.
  */
 
 template {
   display: none;
 }
 
 /* User interaction
  * ========================================================================== */
 
 /*
  * 1. Remove the tapping delay in IE 10.
  * 2. Remove the tapping delay on clickable elements
       in all browsers (opinionated).
  */
 
 a,
 area,
 button,
 input,
 label,
 select,
 summary,
 textarea,
 [tabindex] {
   -ms-touch-action: manipulation; /* 1 */
   touch-action: manipulation; /* 2 */
 }
 
 /**
  * Add the correct display in IE 10-.
  */
 
 [hidden] {
   display: none;
 }
 
 /* Accessibility
  * ========================================================================== */
 
 /**
  * Change the cursor on busy elements in all browsers (opinionated).
  */
 
 [aria-busy="true"] {
   cursor: progress;
 }
 
 /*
  * Change the cursor on control elements in all browsers (opinionated).
  */
 
 [aria-controls] {
   cursor: pointer;
 }
 
 /*
  * Change the cursor on disabled, not-editable, or otherwise
  * inoperable elements in all browsers (opinionated).
  */
 
 [aria-disabled="true"],
 [disabled] {
   cursor: not-allowed;
 }
 
 /*
  * Change the display on visually hidden accessible elements
  * in all browsers (opinionated).
  */
 
 [aria-hidden="false"][hidden]:not(:focus) {
   clip: rect(0, 0, 0, 0);
   display: inherit;
   position: absolute;
 }
//
// -- FLEXBOXGRID -- //
//

@import "flex";

// Set the number of columns you want to use on your layout.
$flexboxgrid-grid-columns: 12 !default;
// Set the gutter between columns.
$flexboxgrid-gutter-width: 1rem !default;
// Set a margin for the container sides.
$flexboxgrid-outer-margin: 2rem !default;
// Create or remove breakpoints for your project
// Syntax:
// name SIZErem,
$flexboxgrid-breakpoints:
  sm 48em 46rem,
  md 62em 61rem,
  lg 75em 71rem !default;
$flexboxgrid-max-width: 1200px !default;

//
// -- Stop editing -- //
//

$gutter-compensation: $flexboxgrid-gutter-width * .5 * -1;
$half-gutter-width: $flexboxgrid-gutter-width * .5;

.wrapper {
  box-sizing: border-box;
  max-width: $flexboxgrid-max-width;
  margin: 0 auto;
}

.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-right: $flexboxgrid-outer-margin;
  padding-left: $flexboxgrid-outer-margin;
}

.row {
  box-sizing: border-box;
  @include flexbox();
  @include flex(0, 1, auto);
  @include flex-direction(row);
  @include flex-wrap(wrap);
  margin-right: $gutter-compensation;
  margin-left: $gutter-compensation;
}

.row.reverse {
  @include flex-direction(row-reverse);
}

.col.reverse {
  @include flex-direction(column-reverse);
}

@mixin flexboxgrid-sass-col-common {
  box-sizing: border-box;

  // split @include flex(0, 0, auto) into individual props
  @include flex-grow(0);
  @include flex-shrink(0);

  // we leave @include flex-basis(auto) out of common because
  // in some spots we need it and some we dont
  // more why here: https://github.com/kristoferjoseph/flexboxgrid/issues/126

  padding-right: $half-gutter-width;
  padding-left: $half-gutter-width;
}

$name: xs;
.col-#{$name} {
  @include flexboxgrid-sass-col-common;
  @include flex-basis(auto);
}
@for $i from 1 through $flexboxgrid-grid-columns {
  .col-#{$name}-#{$i} {
    @include flexboxgrid-sass-col-common;
    @include flex-basis(100% / $flexboxgrid-grid-columns * $i);
    max-width: 100% / $flexboxgrid-grid-columns * $i;
  }
}
@for $i from 0 through $flexboxgrid-grid-columns {
  .col-#{$name}-offset-#{$i} {
    @include flexboxgrid-sass-col-common;
    @if $i == 0 {
      margin-left: 0;
    } @else {
      margin-left: 100% / $flexboxgrid-grid-columns * $i;
    }
  }
}
.col-#{$name} {
  @include flex-grow(1);
  @include flex-basis(0);
  max-width: 100%;
}
.start-#{$name} {
  @include justify-content(flex-start);
  text-align: left;
}

.center-#{$name} {
  @include justify-content(center);
  text-align: center;
}

.end-#{$name} {
  @include justify-content(flex-end);
  text-align: right;
}

.top-#{$name} {
  @include align-items(flex-start);
}

.middle-#{$name} {
  @include align-items(center);
}

.bottom-#{$name} {
  @include align-items(flex-end);
}

.around-#{$name} {
  @include justify-content(space-around);
}

.between-#{$name} {
  @include justify-content(space-between);
}

.first-#{$name} {
  order: -1;
}

.last-#{$name} {
  order: 1;
}


@each $breakpoint in $flexboxgrid-breakpoints {
  $name: nth($breakpoint, 1);
  $size: nth($breakpoint, 2);
  $container: nth($breakpoint, 3);
  @media only screen and (min-width: $size) {
    .container {
      width: $container;
    }

    .col-#{$name} {
      @include flexboxgrid-sass-col-common;
      @include flex-basis(auto);
    }
    @for $i from 1 through $flexboxgrid-grid-columns {
      .col-#{$name}-#{$i} {
        @include flexboxgrid-sass-col-common;
        @include flex-basis(100% / $flexboxgrid-grid-columns * $i);
        max-width: 100% / $flexboxgrid-grid-columns * $i;
      }
    }
    @for $i from 0 through $flexboxgrid-grid-columns {
      .col-#{$name}-offset-#{$i} {
        @include flexboxgrid-sass-col-common;
        @if $i == 0 {
          margin-left: 0;
        } @else {
          margin-left: 100% / $flexboxgrid-grid-columns * $i;
        }
      }
    }
    .col-#{$name} {
      @include flex-grow(1);
      @include flex-basis(0);
      max-width: 100%;
    }
    .start-#{$name} {
      @include justify-content(flex-start);
      text-align: left;
    }

    .center-#{$name} {
      @include justify-content(center);
      text-align: center;
    }

    .end-#{$name} {
      @include justify-content(flex-end);
      text-align: right;
    }

    .top-#{$name} {
      @include align-items(flex-start);
    }

    .middle-#{$name} {
      @include align-items(center);
    }

    .bottom-#{$name} {
      @include align-items(flex-end);
    }

    .around-#{$name} {
      @include justify-content(space-around);
    }

    .between-#{$name} {
      @include justify-content(space-between);
    }

    .first-#{$name} {
      order: -1;
    }

    .last-#{$name} {
      order: 1;
    }
  }
}
