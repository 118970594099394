@font-face {
    font-family: 'Qanelas';
    src: url('../fonts/src-font/Qanelas-Heavy.woff2') format('woff2'),
        url('../fonts/src-font/Qanelas-Heavy.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Qanelas';
    src: url('../fonts/src-font/Qanelas-Medium.woff2') format('woff2'),
        url('../fonts/src-font/Qanelas-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}


